import { getEnv } from "@/utils";
import { App, State } from "vue";
import * as Sentry from "@sentry/vue";
import { User, UserManager, UserManagerSettings } from "oidc-client-ts";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
} from "vue-router";
import { Store } from "vuex";

declare const APP_VERSION: string;

let instance: UserManager;
let settings: UserManagerSettings;
let router: Router;
let store: Store<State>;
// Log.setLevel(Log.DEBUG);
// console.debug = console.log;
// Log.setLogger(console);

const schema = window.location.protocol;
const domain = window.location.host;
const host = `${schema}//${domain}`;

enum CallbackStatus {
  NoCallback,
  Login,
  Logout,
  Error,
}

function isLoginCallback(): boolean {
  // console.log(window.location.pathname);
  return (
    window.location.pathname === "/login" &&
    (window.location.search.includes("&code=") ||
      window.location.search.includes("?code=")) &&
    (window.location.search.includes("&state=") ||
      window.location.search.includes("?state="))
  );
}

function isLogoutCallback(): boolean {
  return window.location.pathname === "/logout";
}

function isErrorCallback(): boolean {
  return window.location.search.includes("error=login_required");
}

function getCurrentCallbackStatus(): CallbackStatus {
  if (isLoginCallback()) {
    return CallbackStatus.Login;
  }

  if (isLogoutCallback()) {
    return CallbackStatus.Logout;
  }

  if (isErrorCallback()) {
    return CallbackStatus.Error;
  }

  return CallbackStatus.NoCallback;
}

export class OpenIdConnectPlugin {
  private isLogoutRequested = false;

  constructor(options: UserManagerSettings) {
    settings = {
      ...options,
      ...{},
    };
  }

  install(app: App, vueRouter: Router, vueStore: Store<State>): void {
    if (instance === undefined) {
      router = vueRouter;
      store = vueStore;
      instance = new UserManager(settings);
      instance.events.addSilentRenewError((): void => {
        store.commit("user/remove");
        instance.removeUser();
        instance.signinRedirect();
      });
      instance.events.addUserLoaded((user: User): void => {
        // console.info("[OIDC] - User Loaded");
        Sentry.setTag("uid", user.profile.sub || "");
        store.dispatch("user/set", user);
      });
      // this.setUserToStore();
      router.beforeEach(this.guard());
    }
    app.config.globalProperties.$auth = this;
  }

  async authenticated(): Promise<boolean> {
    const user = await instance.getUser();
    /*
    console.info(
      "is authenticated " + user !== undefined &&
        user !== null &&
        (!user.expired || false)
    );
    */
    return Promise.resolve(
      user !== undefined && user !== null && (!user.expired || false)
    );
    // return Promise.resolve(
    //   user !== null
    //     ? (user.expires_at || 0) > new Date().getTime() / 1000
    //     : false
    // );
  }

  logout(): Promise<void> {
    // return Promise.resolve();
    // instance.removeUser();
    // store.commit("user/remove");
    // console.info('[OIDC] - Logout');
    this.isLogoutRequested = true;
    return instance.signoutRedirect();
  }

  setUserToStore(): Promise<void> {
    return new Promise<void>((resolve, rejected) => {
      instance.getUser().then((user: User | null) => {
        if (user) {
          store.dispatch("user/set", user);
          // TODO should be  HttpOnly
          // document.cookie = "token=" + user.access_token + "; path=/; Secure; HttpOnly";

          // Main Domain
          let hostName = domain;
          const parts = hostName.split(".");
          // Extract main domain and TLD
          if (parts.length > 2) {
            hostName = parts.slice(parts.length - 2).join(".");
          } else if (hostName.startsWith("localhost:")) {
            hostName = "localhost";
          }

          // console.log("cookie domain=" + hostName);
          document.cookie =
            "token=" +
            user.access_token +
            ";domain=" +
            hostName +
            ";path=/;SameSite=None; Secure";
          return resolve();
        }

        return rejected("user not present");
      });
    });
  }

  guard(): NavigationGuard {
    return async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      const isAuthenticated = await this.authenticated();
      // console.info(`[GUARD] - check auth`, to);
      if (isAuthenticated) {
        // console.info(`[GUARD] - is authenticated`);
        this.setUserToStore();
        return next();
      }
      // console.info('[GUARD] - check callback');
      switch (getCurrentCallbackStatus()) {
        case CallbackStatus.Login:
          // console.info("[GUARD] - Login callback");
          return instance
            .signinRedirectCallback()
            .then(() => {
              // console.info("[GUARD] - next");
              next();
              // TODO : get Prev URL from Cache and redirect
            })
            .catch((e) => {
              // router.push({ name: "Home" });
              console.log(e);
            });
        case CallbackStatus.Logout:
          // console.info('[GUARD] - Logout callback');
          window.location.href = "/";
          return next();
        // break;

        case CallbackStatus.Error:
          // console.info("[GUARD] - Error callback");
          return instance.signinRedirect();
        default:
          // console.info("[GUARD] - No callback");
          if (!this.isLogoutRequested) {
            return instance.signinRedirect();
          }
          next();
      }
    };
  }
}

function createOpenIdConnect(
  options: UserManagerSettings
): OpenIdConnectPlugin {
  return new OpenIdConnectPlugin(options);
}

export function getOIDCInstance(): UserManager | undefined {
  return instance;
}

let userId: string;

const getId = () => {
  if (userId === undefined) {
    instance.getUser().then((user) => {
      if (user) {
        userId = user.profile.sub || "";
      }
    });
  }
  return userId || "";
};

export default createOpenIdConnect({
  authority: getEnv("oidcAuthAuthority", "not_defined"),
  client_id: getEnv("oidcAuthClient", "not_defined"),
  redirect_uri: `${host}/login`,
  // redirect_uri: `${host}`,
  post_logout_redirect_uri: `${host}/logout`,
  response_type: "code",
  scope: "openid profile roles public-tribes",
  // popupRedirectUri: `${host}/signin`,
  // popupPostLogoutRedirectUri: `${host}/signout`,
  // silent_redirect_uri: `${host}`,
  automaticSilentRenew: true,
  // revokeAccessTokenOnSignout: true,
  // accessTokenExpiringNotificationTime: parseInt(getEnv("REFRESH_TIME"), 0),
  // filterProtocolClaims: true,
  loadUserInfo: false,
  // loadUserInfo: true,
  extraHeaders: {
    "X-Uid": getId,
    "X-App": `interface-pro v${APP_VERSION}`,
  },
});
