
import { Options, Vue } from "vue-class-component";
import { ModelSync, Prop, Watch } from "vue-property-decorator";

@Options({})
export default class SearchField extends Vue {
  @ModelSync("search", "update:search", { type: String })
  private searchValue!: string;

  @Prop({}) readonly placeholder?: string;
  @Prop({ default: 100 }) readonly waitTime!: number;
  @Prop({ default: 3 }) readonly maxTick!: number;
  @Prop({ default: false }) readonly forceexpended!: boolean;

  private count = 0;
  private to!: unknown;
  searchCacheValue = "";

  @Watch("searchValue")
  private updateValue(search: string): void {
    this.searchCacheValue = search;
  }

  mounted(): void {
    if (this.searchValue !== undefined && this.searchValue.length !== 0) {
      this.expend();
    }
  }

  get isEmpty(): boolean {
    return (
      this.searchCacheValue === undefined || this.searchCacheValue.length === 0
    );
  }

  isExpended(): boolean {
    if (this.forceexpended) {
      return true;
    }

    const input = this.$el?.querySelector("input");
    return !this.isEmpty || (input ? input === document.activeElement : false);
  }

  typing(): void {
    if (this.to) {
      clearTimeout(this.to as number);
    }
    this.to = setTimeout(() => {
      this.count = this.maxTick;
      this.tick();
    }, this.waitTime);
  }

  expend(): void {
    if (!this.isExpended() && !this.forceexpended) {
      this.searchCacheValue = this.searchValue;
      (this.$el as HTMLElement).classList.add("expended");
      setTimeout(() => {
        this.$el.querySelector("input").focus();
      }, 100);
    }
  }

  focusOut(): void {
    if (this.isEmpty && !this.forceexpended) {
      this.close();
    }
  }

  private close(): void {
    this.$el.querySelector("input").blur();
    (this.$el as HTMLElement).classList.remove("expended");
  }

  private tick(): void {
    this.count--;
    if (this.count === 0) {
      this.searchValue = this.searchCacheValue;
      delete this.to;
      return;
    }
    this.to = setTimeout(() => {
      this.tick();
    }, this.waitTime);
  }

  reset(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    this.searchValue = this.searchCacheValue = "";
    if (this.to) {
      clearTimeout(this.to as number);
    }
    delete this.to;
    this.close();
  }
}
