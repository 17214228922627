import { Collection, TribeMember, TribeMemberStatus } from "@/store/models";
import {
  AbstractRepository,
  CollectionNextLoader,
  RepositoryFilters,
  StandardRepositoryFilters,
} from "./abstractRepository";
import AbstractSubTribeRepository from "./abstractSubTribeRepository";
import { getOrgMembersRepository } from ".";
import { OrgMembersRepositoryFilters } from "./orgMembersRepository";

/* 
Use directory user service for unkwown members in organisation
when tribe is external
*/

let userServiceTribeMembersRepository: UserServiceTribeMembersRepository;

export interface UserServiceTribeMembersRepositoryFilters
  extends RepositoryFilters {
  userIds?: string[];
}

export class UserServiceTribeMembersFilters
  extends StandardRepositoryFilters
  implements UserServiceTribeMembersRepositoryFilters {}

class UserServiceTribeMembersRepository extends AbstractRepository<TribeMember> {
  constructor() {
    super({
      subject: "TribeInformationSubscribers",
      endpoint: "directory",
      type: "users",
      TypeConstructor: TribeMember,
    });
  }

  public find(
    filter?: UserServiceTribeMembersRepositoryFilters
  ): Promise<Collection<TribeMember>> {
    return this.api.client.post(`${this.endpoint}`, filter).then((response) => {
      return this.createCollection(response.data.result || [], {}, {});
    });
  }
}

export function getUserServiceTribeMembersRepository(): UserServiceTribeMembersRepository {
  if (userServiceTribeMembersRepository === undefined) {
    userServiceTribeMembersRepository = new UserServiceTribeMembersRepository();
  }
  return userServiceTribeMembersRepository;
}

export interface TribeMembersRepositoryFilters extends RepositoryFilters {
  userIds?: string[];
}

export class TribeMembersFilters
  extends StandardRepositoryFilters
  implements TribeMembersRepositoryFilters
{
  userIds?: string[];
}

export default class TribeMembersRepository extends AbstractSubTribeRepository<TribeMember> {
  constructor() {
    super({
      subject: "TribeInformationSubscribers",
      endpoint: "{id}/subscribers",
      type: "publictribes",
      subType: "subscribers",
      TypeConstructor: TribeMember,
    });
  }

  public find(filters?: RepositoryFilters): Promise<Collection<TribeMember>> {
    return super.find(filters).then((collection) => {
      return this.collectionDirectoryStep(collection);
    });
  }

  public areSubscribers(
    filters?: TribeMembersRepositoryFilters
  ): Promise<Collection<TribeMember>> {
    return super.find(filters);
  }

  public save(): Promise<TribeMember> {
    return Promise.reject("No implemented");
  }

  public delete(): Promise<TribeMember> {
    return Promise.reject("No implemented");
  }

  public add(memberId: string): Promise<void> {
    if (!this.abilities.can("create", this.options.subject)) {
      return Promise.reject(
        new Error(`Adding member in ${this.id} is not allowed`)
      );
    }
    return this.api.client.post(`${this.endpoint}/${memberId}`);
  }

  public remove(memberId: string): Promise<void> {
    if (!this.abilities.can("delete", this.options.subject)) {
      return Promise.reject(
        new Error(`Removing member in ${this.id} is not allowed`)
      );
    }
    return this.api.client.delete(`${this.endpoint}/${memberId}`);
  }

  protected collectionNextLoader(): CollectionNextLoader<TribeMember> {
    return (collection): Promise<Collection<TribeMember>> => {
      return super
        .collectionNextLoader()(collection)
        .then((final) => {
          return this.collectionDirectoryStep(final);
        });
    };
  }

  private collectionDirectoryStep(
    collection: Collection<TribeMember>
  ): Promise<Collection<TribeMember>> {
    const filters: OrgMembersRepositoryFilters =
      new OrgMembersRepositoryFilters();
    if (collection.length == 0) {
      return Promise.resolve(collection);
    }
    filters.userIds = collection.entries.map((member) => member.userId || "");
    filters.descendant = true;

    return getOrgMembersRepository()
      .find(filters)
      .then((directory) => {
        const unknownMembers: string[] = [];
        const result = new Collection(
          collection.entries.map((val) => {
            const item = directory.entries.find((dir) => dir.id === val.userId);
            if (item) {
              val.firstName = item.firstName;
              val.lastName = item.lastName;
              val.messagingId = item.messagingId;
              val.state = val.firstName
                ? TribeMemberStatus.Subscribed
                : TribeMemberStatus.Preflighted;
              val.displayName = item.displayName;
              val.orgMember = true;
            } else {
              unknownMembers.push(val.userId!);
            }
            return val;
          }),
          collection.info,
          collection.links,
          this.collectionNextLoader()
        );

        // consolidate with users directory
        if (unknownMembers.length > 0) {
          const dirFilters: UserServiceTribeMembersRepositoryFilters =
            new UserServiceTribeMembersFilters();
          dirFilters.userIds = unknownMembers;

          getUserServiceTribeMembersRepository()
            .find(dirFilters)
            .then((response) => {
              response.entries.forEach((val) => {
                const item = result.entries.find(
                  (res) => res.userId === val.userId
                );
                if (item) {
                  item.firstName = val.firstName;
                  item.lastName = val.lastName;
                  item.messagingId = val.messagingId;
                  item.state = val.state;
                  item.orgMember = false;
                }
                return val;
              });
            });
        }
        return result;
      });
  }
}
