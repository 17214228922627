import { getEnv } from "@/utils";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import createDefaultClient from "@/services/api/client";
import {
  IEvent,
  IJoinedRoom,
  IRooms,
  ISyncRequest,
  ISyncResponse,
} from "./types";
import { Store } from "vuex";
import { State } from "vue";
import { ChatRoom, ChatRoomCollection, simplifyRoomId } from "./models";
import { EventReceiptModes } from "./models/events";

let chatClient: ChatClient;

interface ChatClientConfig extends AxiosRequestConfig {
  messageLimit?: number;
}

const DefaultChatClientConfig: Partial<ChatClientConfig> = {
  baseURL: `${getEnv("treebalApiUrlMatrix")}/_matrix/`,
  timeout: 30000,
  messageLimit: 30,
};

const MXCReg = /mxc:\/\/(.+)\/(.+)/i;

export enum MXCMethods {
  Thumbnail = "thumbnail",
  Full = "full",
}

type ComputeResults = {
  rooms: Record<string, ChatRoomCollection>;
  tribeRooms: Record<string, string>;
};

function computeRoomsFromSync(
  rooms: Record<string, IJoinedRoom>,
  store: Store<State>
): ComputeResults {
  const roomsResult: Record<string, ChatRoomCollection> = {};
  const tribeRoomsResult: Record<string, string> = {};
  for (const k in rooms) {
    const room = new ChatRoom(k, rooms[k]); //, simplifyRoomId(k) === '!DvYpjKyjnohyKjIeeJ');
    if (room.tribeId === "unknown" && store.state.chat?.tribesRooms[room.id]) {
      room.tribeId = store.state.chat.tribesRooms[room.id];
    }
    if (roomsResult[room.tribeId] === undefined) {
      roomsResult[room.tribeId] = {};
    }
    tribeRoomsResult[room.id] = room.tribeId;
    roomsResult[room.tribeId][room.id] = room;
  }

  return {
    rooms: roomsResult,
    tribeRooms: tribeRoomsResult,
  };
}

class ChatClientError extends Error {
  name = "ChatClientError";
  cause: AxiosResponse<unknown>;

  constructor(response: AxiosResponse<unknown>) {
    super(response.statusText);
    this.cause = response;
  }
}

class ChatClient {
  private apiClient: AxiosInstance;
  private config: ChatClientConfig;
  private store?: Store<State>;
  private apiVersion = "r0";
  private errorCount = 0;

  constructor(config: AxiosRequestConfig) {
    this.config = { ...config, ...DefaultChatClientConfig };
    this.apiClient = createDefaultClient({
      ...this.config,
      ...{ timeout: (this.config.timeout || 0) + 1000 },
    });
  }

  start(store: Store<State>): void {
    this.store = store;
    this.getWhoami().then(() => this.sync());
  }

  getWhoami(): Promise<void> {
    return this.apiClient
      .get(`${this.clientPathURL}account/whoami`)
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        if (response.data && response.data["user_id"]) {
          return this.store?.commit("chat/iam", response.data["user_id"]);
        }
        throw new ChatClientError(response);
      });
  }

  getThumbnailUrl(mxc: string, attributes?: string): string {
    return this.getMXCContentUrl(mxc, MXCMethods.Thumbnail, attributes);
  }

  getFullContentUrl(mxc: string, attributes?: string): string {
    return this.getMXCContentUrl(mxc, MXCMethods.Full, attributes);
  }

  getMXCContentUrl(
    mxc: string,
    method = MXCMethods.Full,
    attributes?: string
  ): string {
    const match = mxc.match(MXCReg);
    let url: string;
    let methodPath;
    if (match == null) {
      throw new Error(`Get content MXC failed (${mxc})`);
    }

    switch (method) {
      case MXCMethods.Full:
        methodPath = "download";
        break;
      case MXCMethods.Thumbnail:
        methodPath = "thumbnail";
        break;
      default:
        throw new Error(`MXC method unknown (${method})`);
    }

    url = `${this.config.baseURL}${this.mediaPathURL}${methodPath}/${match[1]}/${match[2]}`;
    if (attributes) {
      url = `${url}?${attributes}`;
    }

    return url;
  }

  getPreviousMessages(room: ChatRoom): Promise<void> {
    if (room.previousTimelineBatch) {
      return this.getPreviousMatrixMessages(room).then(() => {
        if (room.previousTimelineBatch === undefined) {
          return this.getOlderMessages(room);
        }
      });
    }

    return this.getOlderMessages(room);
  }

  getPreviousMatrixMessages(room: ChatRoom): Promise<void> {
    return this.apiClient
      .get(`${this.clientPathURL}rooms/${room.fullId}/messages`, {
        params: {
          from: room.previousTimelineBatch,
          // to: room.previousTimelineBatch,
          dir: "b",
          // dir: "f",
          limit: this.config.messageLimit || 10,
          // filter: JSON.stringify({
          //   types: VisibleEventTypes,
          // }, null, ''),
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        if (
          response.data &&
          response.data.chunk &&
          Array.isArray(response.data.chunk)
        ) {
          if (response.data.chunk.length > 0) {
            room.previousTimelineBatch = response.data.end;

            if (response.data.chunk.length < (this.config.messageLimit || 10)) {
              delete room.previousTimelineBatch;
            }

            return this.store?.dispatch("chat/prependRoomMessages", {
              roomId: room.id,
              messages: response.data.chunk,
            });
          }
          delete room.previousTimelineBatch;
        }
      });
  }

  getOlderMessages(room: ChatRoom): Promise<void> {
    return this.apiClient
      .get(`${this.clientPathURL}rooms/${room.fullId}/messages`, {
        params: {
          beforeequal:
            (room.olderEventTime?.getTime() || new Date().getTime()) - 1,
          limit: this.config.messageLimit || 10,
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        if (
          response.data &&
          response.data.chunk &&
          Array.isArray(response.data.chunk) &&
          response.data.chunk.length > 0
        ) {
          // console.log("[ChatClient] - GetOlderMessage", response.data.chunk)
          return this.store?.dispatch("chat/prependRoomMessages", {
            roomId: room.id,
            messages: response.data.chunk,
            mode: EventReceiptModes.Proxy,
          });
        }
      });
  }

  // https://dev.wangari.dolmen.green/_matrix/client/r0/user/%400db97f41-bb2c-4145-b8ff-419248cea15a%3Amatrix-dev.wangari.dolmen.green/rooms/%21ijzvwqRYZrSsiKREod%3Amatrix-dev.wangari.dolmen.green/tags/t.unread
  setUnreadTagRoom(room: ChatRoom, unreadTag: boolean): Promise<void> {
    const userId = this.store?.state.chat?.iam;
    const orderParam = { order: 0.18 };

    if (userId && unreadTag) {
      return this.apiClient
        .put(
          `${this.clientPathURL}user/${userId}/rooms/${room.fullId}/tags/t.unread`,
          orderParam
        )
        .then((response) => {
          if (response.status >= 400) {
            throw new ChatClientError(response);
          }
          room.unreadTag = true;
          return;
        })
        .finally(() => {
          return;
        });
    } else if (userId) {
      return this.apiClient
        .delete(
          `${this.clientPathURL}user/${userId}/rooms/${room.fullId}/tags/t.unread`
        )
        .then((response) => {
          if (response.status >= 400) {
            throw new ChatClientError(response);
          }
          room.unreadTag = false;
          return;
        })
        .finally(() => {
          return;
        });
    } else {
      return new Promise((resolve) => resolve());
    }
  }

  send(event: IEvent): Promise<IEvent> {
    return this.apiClient
      .put(
        `${this.clientPathURL}rooms/${event.room_id}/send/${event.type}/${
          event.txn_id || ""
        }`,
        event.content
      )
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        event.event_id = (response.data as IEvent).event_id;
        return event;
      })
      .finally(() => {
        return event;
      });
  }

  receipt(event: IEvent): Promise<void> {
    return this.apiClient
      .post(
        `${this.clientPathURL}rooms/${event.room_id}/receipt/${event.type}/${event.event_id}`
      )
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        if (event.room_id) {
          this.store?.dispatch(
            "chat/removeRoomNotifications",
            simplifyRoomId(event.room_id)
          );
        }
      });
  }

  leave(room: ChatRoom): Promise<void> {
    return this.apiClient
      .post(`${this.clientPathURL}rooms/${room.fullId}/leave`)
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
      });
  }

  private sync(timeout?: number): Promise<void> {
    const data: ISyncRequest = {
      timeout: timeout || 0,
      _cacheBuster: timeout ? undefined : Date.now(),
      since: this.store?.state.chat?.nextBatch,
    };

    return this.apiClient
      .get(`${this.clientPathURL}sync`, {
        params: data,
      })
      .then((response) => {
        if (response.status >= 400) {
          throw new ChatClientError(response);
        }
        this.errorCount = 0;
        const data = response.data as ISyncResponse;
        this.store?.commit("chat/setNextBatch", data.next_batch);
        this.processSyncResponse(data);
      })
      .catch((reason) => {
        this.errorCount += 1;
        throw reason;
      })
      .finally(() => {
        setTimeout(
          () => this.sync(this.config.timeout || 0),
          this.errorCount * 1000
        );
      });
  }

  private processSyncResponse(response: ISyncResponse): void {
    if (response.rooms) {
      this.processSyncRooms(response.rooms);
    }
  }

  private processSyncRooms(rooms: IRooms): void {
    if (rooms.join && Object.keys(rooms.join).length > 0) {
      const roomsCollection = this.store?.state.chat?.rooms;
      const dispatchFnName =
        roomsCollection && Object.keys(roomsCollection).length > 0
          ? "updateRooms"
          : "saveRooms";
      this.store?.dispatch(
        `chat/${dispatchFnName}`,
        computeRoomsFromSync(rooms.join, this.store)
      );
    }
    if (rooms.leave) {
      this.store?.dispatch(
        "chat/removeRooms",
        Object.keys(rooms.leave).map((id) => simplifyRoomId(id))
      );
    }
  }

  private get clientPathURL(): string {
    return `client/${this.apiVersion}/`;
  }

  private get mediaPathURL(): string {
    return `media/${this.apiVersion}/`;
  }
}

export function getChatClient(): ChatClient {
  if (chatClient === undefined) {
    chatClient = new ChatClient({});
  }

  return chatClient;
}
